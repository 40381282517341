import store from '../store';

/*global gapi*/
var GoogleAuth;

const initAuth = () => {
  store.dispatch('setLoading', true);
  // Wait for gapi to load the client
  gapi.load('client', {
    callback: () => {
      // Handle gapi.client initialization.
      gapi.client
        .init({
          apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
          clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
          scope: 'profile email',
          prompt: 'select_account',
        })
        .then(() => {
          GoogleAuth = gapi.auth2.getAuthInstance();
          updateSigninStatus(GoogleAuth.isSignedIn);
          // Listen for sign-in state changes.
          GoogleAuth.isSignedIn.listen(() => {
            updateSigninStatus(GoogleAuth.isSignedIn);
          });
          store.dispatch('setLoading', false);
        });
    },
    onerror() {
      store.dispatch('setLoading', false);
    },
    timeout: 5000,
    ontimeout() {
      store.dispatch('setLoading', false);
    },
  });
};

const handleAuthClick = () => {
  if (GoogleAuth) {
    if (GoogleAuth.isSignedIn.get()) {
      // User is authorized and has clicked "Sign out" button.
      GoogleAuth.signOut().then(() => {
        GoogleAuth.isSignedIn.set(false);
      });
    } else {
      // User is not signed in. Start Google auth flow.
      if (GoogleAuth) {
        GoogleAuth.signIn();
      }
    }
  } else {
    initAuth();
  }
};

// If sign in status changes set user and push
const updateSigninStatus = (isSignedIn) => {
  if (isSignedIn.get()) {
    let user = GoogleAuth.currentUser.get().getBasicProfile();
    store.dispatch('setUser', { user });
  }
};

export { initAuth, handleAuthClick };
