<template>
  <div :class="$style.tile">
    <slot></slot>
    <h1>{{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: 'BaseTile',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" module>
.tile {
  display: flex;
  flex-direction: column;
  text-align: left;
  border: $primary;
  border-style: solid;
  border-width: 2px;
  padding: $spacing-default;

  svg {
    width: clamp(2rem, 10vw, 3rem);
    height: clamp(2rem, 10vw, 3rem);
  }

  path {
    fill: $primary;
  }

  h1 {
    font-size: clamp(0.8rem, 2.5vw, 1.25rem);
    &:after {
      content: '';
      display: inline-block;
      width: 0.25rem;
      height: 0.25rem;
      -moz-border-radius: 1rem;
      -webkit-border-radius: 1rem;
      border-radius: 50px;
      background-color: $secondary;
      margin-left: 2px;
    }
  }
}
</style>
