<template>
  <div :class="$style.nav">
    <div :class="$style.arrow">
      <svg
        v-if="backIcon"
        @click="$router.push('/')"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.1417 2L18.5 4.3575L10.7175 12.0033L18.5 19.6425L16.1417 22L6 12.0033L16.1417 2Z"
          fill="white"
        />
      </svg>
      <svg
        v-else-if="logoutIcon"
        @click="logout"
        viewBox="0 0 36 36"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 33L12 22.5L15 22.5L15 30L33 30L33 6L15 6L15 13.5L12 13.5L12 3L36 3L36 33L12 33ZM9 19.5L9 25.5L1.31134e-06 18L9 10.5L9 16.5L24 16.5L24 19.5L9 19.5Z"
          fill="white"
        />
      </svg>
    </div>
    <div :class="$style.logo">
      <img :src="logo" alt="logo" />
    </div>
    <div :class="$style.profile">
      <img
        v-if="profileIcon && colleague && colleague.img_url"
        :src="colleague.img_url"
        @click="toProfile"
        alt="profiel foto"
      />
      <svg
        v-else-if="profileIcon && colleague && !colleague.img_url"
        @click="toProfile"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 5C13.66 5 15 6.34 15 8C15 9.66 13.66 11 12 11C10.34 11 9 9.66 9 8C9 6.34 10.34 5 12 5ZM12 19.2C9.5 19.2 7.29 17.92 6 15.98C6.03 13.99 10 12.9 12 12.9C13.99 12.9 17.97 13.99 18 15.98C16.71 17.92 14.5 19.2 12 19.2Z"
          fill="white"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { handleAuthClick } from '@/helpers/auth';

export default {
  name: 'Navbar',
  props: {
    backIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    profileIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    logoutIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    logo() {
      return require(`../styles/brands/${process.env.VUE_APP_BRAND}/${this.$whitelabel.icon}`);
    },
    colleague() {
      return this.$store.getters.getColleagueById(this.$store.state.user.id);
    },
  },
  methods: {
    toProfile() {
      this.$router.push({
        path: `/profile/${this.$store.state.user.id}`,
      });
    },
    logout() {
      handleAuthClick();
      this.$router.push('/login');
      this.$store.dispatch('resetUser');
    },
  },
};
</script>

<style lang="scss" module>
.nav {
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  grid-template-rows: auto;
  padding: $spacing-default;
  align-items: center;

  .logo {
    img {
      width: 100%;
      max-width: 40px;
      height: auto;
    }
  }
  .arrow {
    align-self: center;
    width: 2rem;
  }
  path {
    fill: $primary;
  }

  .profile {
    display: flex;
    align-items: center;
    img {
      width: auto;
      height: 40px;
      border-radius: 50%;
    }
  }

  svg {
    cursor: pointer;
  }
  img {
    cursor: pointer;
  }
}
</style>
