module.exports = {
  light: {
    brand: 'Light Theme',
    scssVariables: './src/styles/brands/light',
    icon: 'logo_light.jpg',
    manifest: {
      name: 'Adwise Memory',
      short_name: 'Adwise Memory',
      theme_color: '#fff',
      background_color: '#000000',
      msTileColor: '#fff',
      appleMobileWebAppStatusBarStyle: '#fff',
    },
  },
  dark: {
    brand: 'Dark Theme',
    scssVariables: './src/styles/brands/dark',
    icon: 'logo_dark.jpg',
    manifest: {
      name: 'Adwise Memory',
      short_name: 'Adwise Memory',
      theme_color: '#433e40',
      background_color: '#000000',
    },
  },
};
