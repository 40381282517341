<template>
  <div :class="$style.container" v-if="options">
    <input
      :class="$style.input"
      @focus="showOptions()"
      @blur="exit()"
      @keyup="keyMonitor"
      v-model="searchFilter"
      :placeholder="'Zoeken'"
    />
    <div :class="$style.content" v-show="optionsShown">
      <div
        :class="$style.item"
        @mousedown="selectOption(option)"
        v-for="(option, index) in filteredOptions"
        :key="index"
      >
        {{ option.firstname }} {{ option.middlename }} {{ option.lastname }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseSearchbar',
  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    maxItem: {
      type: Number,
      required: false,
      default: 4,
    },
  },
  data() {
    return {
      selected: {},
      optionsShown: false,
      searchFilter: '',
    };
  },
  computed: {
    filteredOptions() {
      const filtered = [];
      const regOption = new RegExp(this.searchFilter, 'ig');
      for (const option of this.options) {
        if (this.searchFilter.length < 1 || option.firstname.match(regOption)) {
          if (filtered.length < this.maxItem) filtered.push(option);
        }
      }
      return filtered;
    },
  },
  methods: {
    selectOption(option) {
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.selected.firstname;
      this.$router.push({
        path: `/profile/${option._id}`,
      });
    },
    showOptions() {
      if (!this.disabled) {
        this.searchFilter = '';
        this.optionsShown = true;
      }
    },
    exit() {
      if (!this.selected.id) {
        this.selected = {};
        this.searchFilter = '';
      } else {
        this.searchFilter = this.selected.name;
      }
      this.optionsShown = false;
    },
    keyMonitor(event) {
      if (event.key === 'Enter' && this.filteredOptions[0])
        this.selectOption(this.filteredOptions[0]);
    },
  },
};
</script>

<style lang="scss" module>
.container {
  margin: $spacing-default;
  align-self: center;
  .input {
    box-sizing: border-box;
    background: $bg;
    cursor: pointer;
    color: $primary;
    font-family: $font-family;
    font-size: clamp(1rem, 2.5vw, 1.25rem);
    width: 100%;
    height: clamp(1rem, 2vw, 2rem);
    padding: $spacing-default;
    padding-left: calc(#{$spacing-default} + 2rem);
    border: 2px $primary solid;
    background-image: url('data:image/svg+xml;utf8, <svg width="24" height="24" viewBox="0 0 24 24" fill="#{url-color($primary)}" xmlns="http://www.w3.org/2000/svg"><path d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"/></svg>');
    background-repeat: no-repeat;
    background-position: center left calc(0.375rem + 0.1875rem);

    &:focus {
      outline: none;
      box-shadow: 0px 0px 0px 1px $secondary;
    }
  }
  .content {
    position: absolute;
    left: calc(#{$spacing-default} + #{$spacing-small});
    right: calc(#{$spacing-default} + #{$spacing-small});
    z-index: 20;
    max-width: 952px;
    margin: auto;
    .item {
      background: $tetriary;
      border-bottom: 2px $primary solid;
      font-size: clamp(1rem, 2.5vw, 1.25rem);
      cursor: pointer;
      padding: $spacing-small 0;
      &:hover {
        background-color: $primary;
        color: $bg;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
