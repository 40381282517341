<template>
  <div :class="$style.app">
    <base-loader v-if="loading" />
    <router-view />
  </div>
</template>

<script>
import { initAuth } from '@/helpers/auth';
import BaseLoader from '@/components/BaseLoader';
export default {
  name: 'App',
  components: {
    BaseLoader,
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  mounted() {
    initAuth();
  },
};
</script>

<style lang="scss" module>
.app {
  max-width: 1000px;
  margin: auto;
}
</style>
