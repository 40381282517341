require('./android-chrome-192x192.png');
require('./android-chrome-512x512.png');
require('./android-chrome-maskable-192x192.png');
require('./android-chrome-maskable-512x512.png');
require('./apple-touch-icon-60x60.png');
require('./apple-touch-icon-76x76.png');
require('./apple-touch-icon-120x120.png');
require('./apple-touch-icon-152x152.png');
require('./apple-touch-icon.png');
require('./favicon-16x16.png');
require('./favicon-32x32.png');
require('./msapplication-icon-144x144.png');
require('./mstile-150x150.png');
