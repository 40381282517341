import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import router from '../router';

Vue.use(Vuex);

const api = process.env.VUE_APP_API_URL;
export default new Vuex.Store({
  state: {
    colleagues: [],
    question: null,
    statement: null,
    highscores: null,
    user: null,
    loading: false,
  },
  mutations: {
    SET_DATA(state, data) {
      state.colleagues = data;
    },
    SET_QUESTION(state, question) {
      state.question = question;
    },
    SET_STATEMENT(state, statement) {
      state.statement = statement;
    },
    SET_HIGHSCORES(state, highscores) {
      state.highscores = highscores;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_USERID(state, userId) {
      state.user.id = userId;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  getters: {
    getColleagueById: (state) => (id) => {
      if (state.colleagues) {
        return state.colleagues.find((colleague) => colleague._id === id);
      }
    },
    getColleagueByEmail: (state) => (email) => {
      if (state.colleagues) {
        return state.colleagues.find((colleague) => colleague.email === email);
      }
    },
  },
  actions: {
    fetchData: ({ commit }) => {
      commit('SET_LOADING', true);
      axios.get(api + 'colleagues').then((response) => {
        commit('SET_DATA', response.data);
        commit('SET_LOADING', false);
      });
    },
    fetchQuestion: ({ commit }) => {
      axios.get(api + 'question').then((response) => {
        commit('SET_QUESTION', response.data);
      });
    },
    fetchStatement: ({ commit }) => {
      axios.get(api + 'statement').then((response) => {
        commit('SET_STATEMENT', response.data);
      });
    },
    fetchHighscores: ({ commit }) => {
      commit('SET_LOADING', true);
      axios.get(api + 'highscores').then((response) => {
        commit('SET_HIGHSCORES', response.data);
        commit('SET_LOADING', false);
      });
    },
    setUser: ({ commit }, { user }) => {
      commit('SET_LOADING', true);
      axios.post(api + 'colleague', { user }).then((response) => {
        commit('SET_USERID', response.data);
        commit('SET_LOADING', false);
        router.push('/');
      });

      commit('SET_USER', user);
    },
    resetUser: ({ commit }) => {
      commit('SET_USER', null);
    },
    setLoading: ({ commit }, loading) => {
      commit('SET_LOADING', loading);
    },
    setScore: (_, { score, userId }) => {
      axios.post(api + 'colleague/score', { score, userId });
    },
  },
});
