<template>
  <button>{{ label }}</button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    label: {
      type: String,
      default: '',
      required: false,
    },
  },
};
</script>

<style lang="scss" module>
button {
  background: $bg;
  color: $primary;
  padding: $spacing-small $spacing-default;
  font-size: 1rem;
  border: $primary;
  border-style: solid;
  border-width: 2px;
  font-family: $font-family;
  border-radius: 0;

  &:focus {
    border-radius: 0;
  }

  &:hover {
    cursor: pointer;
  }
}
</style>
