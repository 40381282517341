<template>
  <div :class="$style.header">
    <h1>Welkom terug</h1>
    <h1 :class="$style.name">{{ name }}</h1>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    name: {
      type: String,
      required: false,
      default: 'Gebruiker',
    },
  },
};
</script>

<style lang="scss" module>
.header {
  padding: 0 $spacing-default;
  text-align: left;

  h1 {
    font-size: clamp(1.5rem, 5vw, 2rem);
  }

  .name {
    margin-top: 0;

    &:after {
      content: '';
      display: inline-block;
      width: 0.25rem;
      height: 0.25rem;
      -moz-border-radius: 1rem;
      -webkit-border-radius: 1rem;
      border-radius: 50px;
      background-color: $secondary;
      margin-left: 2px;
    }
  }
}
</style>
