import Vue from 'vue';
import App from '@/App.vue';
import '@/registerServiceWorker';
import router from '@/router';
import store from '@/store';
const whitelabel = require('../whitelabel.config')[process.env.VUE_APP_BRAND];
// import brand icons to be bundled with webpack
require(`./assets/icons/${process.env.VUE_APP_BRAND}/index`);

Vue.config.productionTip = false;
// Add white-label variables to global scope
Vue.prototype.$whitelabel = whitelabel;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
