<template>
  <div :class="$style.container">
    <div :class="$style.spinner">
      <div :class="$style.square"></div>
      <div :class="$style.square"></div>
      <div :class="$style.square"></div>
      <div :class="$style.square"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseLoader',
};
</script>

<style lang="scss" module>
.container {
  position: absolute;
  background: rgba($bg, 0.5);
  height: 100%;
  width: 100%;
}
.spinner,
.spinner * {
  box-sizing: border-box;
}

.spinner {
  height: 65px;
  width: 65px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  animation: animation 1250ms;
  animation-iteration-count: infinite;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
}

.spinner .square {
  height: calc(65px * 0.25 / 1.3);
  width: calc(65px * 0.25 / 1.3);
  margin-right: auto;
  margin-left: auto;
  border: calc(65px * 0.04 / 1.3) solid $primary;
  position: absolute;
  animation-duration: 1250ms;
  animation-iteration-count: infinite;
}

.spinner .square:nth-child(1) {
  animation-name: spinner-animation-child-1;
}

.spinner .square:nth-child(2) {
  animation-name: spinner-animation-child-2;
}

.spinner .square:nth-child(3) {
  animation-name: spinner-animation-child-3;
}

.spinner .square:nth-child(4) {
  animation-name: spinner-animation-child-4;
}

@keyframes animation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(180deg);
  }
}

@keyframes spinner-animation-child-1 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: translate(150%, 150%) scale(2, 2);
  }
}

@keyframes spinner-animation-child-2 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: translate(-150%, 150%) scale(2, 2);
  }
}

@keyframes spinner-animation-child-3 {
  50% {
    transform: translate(-150%, -150%) scale(2, 2);
  }
}

@keyframes spinner-animation-child-4 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: translate(150%, -150%) scale(2, 2);
  }
}
</style>
