<template>
  <div>
    <base-navbar :profileIcon="true" :logoutIcon="true" />
    <div :class="$style.home">
      <div :class="$style.header">
        <base-header :name="colleagueName" />
        <base-searchbar :options="colleagues" />
      </div>
      <div :class="$style.grid">
        <router-link :to="{ name: 'Singleplayer', params: { game: 'quiz' } }">
          <base-tile :title="'Quiz'">
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M44 10.36L21.18 33.2L12.7 24.72L15.52 21.9L21.18 27.56L41.18 7.56L44 10.36ZM39.58 20.44C39.84 21.58 40 22.78 40 24C40 32.84 32.84 40 24 40C15.16 40 8 32.84 8 24C8 15.16 15.16 8 24 8C27.16 8 30.08 8.92 32.56 10.5L35.44 7.62C32.2 5.34 28.26 4 24 4C12.96 4 4 12.96 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 21.62 43.56 19.34 42.8 17.22L39.58 20.44Z"
              />
            </svg>
          </base-tile>
        </router-link>
        <router-link :to="{ name: 'Singleplayer', params: { game: 'recall' } }">
          <base-tile :title="'Recall'">
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33.7027 21.4153L25.9841 13.6967L29.0585 10.6224L33.6809 15.2448L42.9257 6L46 9.07433L33.7027 21.4153ZM21.6234 12.6938H2V17.0545H21.6234V12.6938ZM43.4272 26.67L40.3528 23.5956L34.7056 29.2428L29.0585 23.5956L25.9841 26.67L31.6313 32.3171L25.9841 37.9643L29.0585 41.0387L34.7056 35.3915L40.3528 41.0387L43.4272 37.9643L37.78 32.3171L43.4272 26.67ZM21.6234 30.1368H2V34.4975H21.6234V30.1368Z"
              />
            </svg>
          </base-tile>
        </router-link>
        <router-link to="/highscores">
          <base-tile :title="'Ranglijsten'">
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24 20.5C17.2362 20.5 11.75 25.9845 11.75 32.75C11.75 39.5155 17.2362 45 24 45C30.7638 45 36.25 39.5155 36.25 32.75C36.25 25.9845 30.7638 20.5 24 20.5ZM24 41.5C19.1665 41.5 15.25 37.5817 15.25 32.75C15.25 27.9183 19.1665 24 24 24C28.8335 24 32.75 27.9183 32.75 32.75C32.75 37.5817 28.8335 41.5 24 41.5ZM26.597 33.9487L29.25 31.399L25.6047 30.8967L24 27.5858L22.3953 30.8967L18.75 31.399L21.403 33.9487L20.7573 37.5712L24 35.8353L27.2445 37.5712L26.597 33.9487ZM17 3L27.4265 17H20.5L10 3H17ZM28.557 15.5895L38 3H31L25.092 10.9345L28.557 15.5895Z"
              />
            </svg>
          </base-tile>
        </router-link>
        <!-- <router-link to="/multiplayer"> -->
        <base-tile :class="$style.container" :title="'Multiplayer'">
          <div :class="$style.disabled"></div>
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.238 32.128C24.824 31.068 29.092 30.14 27.026 26.236C20.732 14.354 25.356 8 32.002 8C38.778 8 43.288 14.598 36.978 26.238C34.848 30.166 39.276 31.092 43.764 32.13C47.734 33.046 48 34.986 48 38.344L47.994 40H45.336C45.336 35.822 45.502 35.266 42.884 34.662C39.082 33.786 35.494 32.958 34.182 30.054C33.704 28.994 33.392 27.25 34.634 24.968C37.378 19.904 38.072 15.516 36.532 12.934C34.728 9.9 29.298 9.916 27.508 12.89C25.972 15.436 26.656 19.848 29.38 24.99C30.594 27.282 30.274 29.022 29.792 30.076C28.472 32.966 24.848 33.802 21.012 34.686C18.508 35.266 18.668 35.862 18.668 40H16.006C16.006 35.608 15.654 33.188 20.238 32.128ZM0.004 40H2.662C2.662 36.164 2.29 37.23 6.31 36.054C8.338 35.464 10.13 34.608 10.942 32.83C11.366 31.904 11.652 30.39 10.618 28.436C8.714 24.84 8.18 21.688 9.194 20.006C10.288 18.188 13.734 18.19 14.832 20.036C16.702 23.17 13.246 28 12.792 30H15.584C16.464 28 17.996 25.584 17.996 22.2C17.996 18.18 15.372 16 12 16C7.014 16 3.546 20.766 8.268 29.678C9.816 32.606 6.616 33.302 3.178 34.096C0.198 34.786 0 36.24 0 38.764L0.004 40Z"
            />
          </svg>
        </base-tile>
        <!-- </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
import BaseNavbar from '@/components/BaseNavbar.vue';
import BaseHeader from '@/components/BaseHeader.vue';
import BaseSearchbar from '@/components/BaseSearchbar.vue';
import BaseTile from '@/components/BaseTile.vue';

export default {
  name: 'Home',
  components: {
    BaseNavbar,
    BaseHeader,
    BaseSearchbar,
    BaseTile,
  },
  computed: {
    colleagues() {
      return this.$store.state.colleagues;
    },
    colleagueName() {
      return this.$store.state.user.Ue;
    },
  },
};
</script>

<style lang="scss" module>
.home {
  font-size: clamp(1rem, 2.5vw, 1.25rem);
  padding: 0 $spacing-small;

  .grid {
    margin: $spacing-default;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    gap: $spacing-small;

    a {
      color: $primary;
      text-decoration: none;
    }
    .container {
      position: relative;
      .disabled {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
</style>
