<template>
  <div>
    <base-navbar />
    <div :class="$style.login">
      <h1>
        Klik op de onderstaande knop om in te loggen me je Google Workspace
        account
      </h1>
      <base-button
        :class="$style.button"
        :label="'Login'"
        @click.native="signIn"
      />
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton';
import BaseNavbar from '@/components/BaseNavbar';
import { handleAuthClick } from '@/helpers/auth';

export default {
  name: 'Login',
  components: {
    BaseButton,
    BaseNavbar,
  },
  data() {
    return {};
  },
  methods: {
    signIn() {
      handleAuthClick();
    },
  },
};
</script>

<style lang="scss" module>
.login {
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 1rem;
    margin: $spacing-default;
  }

  .button {
    margin: $spacing-default;
  }
}
</style>
